<template>
  <v-container>
    <v-layout column align-center justify-center class="pa-6 mb-8">``
      <p class="display-3 main-color mx-5">{{$t('certificates.certificates')}}</p>
      <v-subheader>{{ this.$t('certificates.totalCertificates') }}: {{ this.getCertificatesCount.awareded_certificates_count }}</v-subheader>
    </v-layout>
    <v-progress-linear
      v-show="loading"
      indeterminate
      :color="this.primaryColor"
    ></v-progress-linear>
   <Certificate v-for="(certificate, index) in this.certificates" :key="index" :certificate="certificate"/> 
  </v-container>
  
</template>
<script>
import Certificate from './Components/Certificate'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Certificates',
  components: {
    Certificate,
  },
  data () {
    return {
      deleteConfirm: -1,
      loading: false,
      certificates: []
    }
  },
  created () {
    this.loading = true;
    this.fetchCertificates().then(() => {
      console.log(this.getCertificates)
      this.certificates = this.getCertificates
      this.loading = false;
    });

    this.fetchCertificatesCount().then(() => {
      console.log(this.getCertificatesCount)
    })
  },
  methods: {
    ...mapActions("Certificates", ["fetchCertificates", "fetchCertificatesCount"]),
  },
  computed: mapGetters("Certificates", ["getCertificates", "getCertificatesCount"])
}
</script>