<template>
    <v-card class="message pa-3 pb-0" flat>
        <v-layout row wrap class="pa-3">
          <v-flex xs12 md3>
            <div class="grey--text mb-1">{{ $t('certificates.student') }}</div>
            <div>{{ certificate.student_name }}</div>
          </v-flex>
          <v-flex xs12 md3>
            <div class="grey--text mb-1">{{ $t('form.email') }}</div>
            <div>{{ certificate.student_email }}</div>
          </v-flex>
          <v-flex xs12 md2>
            <div class="grey--text mb-1">{{ $t('certificates.course') }}</div>
            <div class="pr-4">{{ certificate.course_title }}</div>
          </v-flex>
          <v-flex xs12 md2>
            <div class="grey--text mb-1">{{ $t('certificates.enrollDate') }}</div>
            <div>{{ certificate.enroll_date | moment }}</div>
          </v-flex>
          <v-flex xs12 md2>
            <div class="grey--text mb-1">{{ $t('certificates.awardDate') }}</div>
            <div>{{ certificate.award_date | moment }}</div>
          </v-flex>
        </v-layout>
      <v-divider class="ma-0 mt-3"></v-divider>
      </v-card>
</template>
<script>
import moment from "moment";
export default {
  name: 'Certificate',
  props: [
    'certificate'
  ],
  filters: {
    moment: function(date) {
      return moment(date).format("YYYY-MM-DD");
    }
  }
}
</script>
<style lang="scss" scoped>
.delete-icon:hover {
  color: darkgray;
  cursor: pointer;
}
.message:nth-of-type(even) {
  border-left: 4px gray solid;
  border-right: 4px gray solid;
}
.message:nth-of-type(odd) {
  border-left: 4px teal solid;
  border-right: 4px teal solid;
}
</style>